<template>
  <div>
    <LoadingSpinner v-if="isLoading" />
    <div v-if="document && !documentDoesNotExist" class="h-full">
      <div
        class="
          relative
          px-10
          py-6
          bg-white
          max-h-[500px]
          xl:max-h-[300px]
          header--transform
        "
        :class="[{ 'hidden-header': isHeaderCollapsed }]"
      >
        <notification ref="notification" :duration="-1" />
        <div
          class="responsive-container"
          :class="{
            'flex items-center justify-between': isFileOnlyDocument,
          }"
        >
          <div v-if="isFileOnlyDocument" class="flex items-center w-full">
            <document-state
              class="self-start"
              :has-labels="false"
              :document="document"
              container-classes="!mt-4 mr-4"
            />
            <h1
              class="
                text-3xl
                xl:text-4xl
                leading-snug
                font-sansbold
                xl:max-w-[66%]
                inline-block
                mr-4
                xl:mr-0
              "
            >
              {{ document.title }}
            </h1>
          </div>
          <document-action-links
            class="flex justify-end mb-3"
            :document="document"
          />
          <div
            v-if="!isFileOnlyDocument"
            class="flex items-center justify-between"
          >
            <div class="flex items-center w-full">
              <document-state
                class="self-start"
                :has-labels="false"
                :document="document"
                container-classes="xl:!mt-4 !mt-3 mr-4"
              />
              <h1
                class="
                  text-3xl
                  xl:text-4xl
                  leading-snug
                  font-sansbold
                  xl:max-w-[66%]
                  mr-4
                  xl:mr-0
                "
              >
                {{ document.title }}
              </h1>
            </div>
            <document-versions
              :versions="document.documentVersions"
              :selected-version="document"
              class="w-[400px]"
            />
          </div>
        </div>
      </div>
      <div
        v-if="isFileOnlyDocument"
        class="flex items-center justify-end px-10 py-3 shadow-md bg-gray-50"
      >
        <document-collapse-header @setHeaderCollapsed="setHeaderCollapsed" />
      </div>
      <div v-if="!isFileOnlyDocument" class="px-10 py-3 shadow-md bg-gray-50">
        <div
          class="flex items-center responsive-container"
          :class="[
            { 'justify-between': tableOfContents.length > 0 },
            { 'justify-end': tableOfContents.length === 0 },
          ]"
        >
          <block-table-of-contents
            v-if="tableOfContents"
            :options="tableOfContents"
            class="w-[400px]"
            :option="currentHeading"
            @input="scrollTo"
          />
          <document-collapse-header @setHeaderCollapsed="setHeaderCollapsed" />
        </div>
      </div>
      <document-protected-notification v-if="isProtectedPDF" />
      <div
        class="grid grid-cols-3 px-10 py-8 4xl:px-0 responsive-container"
        :class="{ 'grid-cols-1': isFileOnlyDocument }"
      >
        <div v-if="!isFileOnlyDocument" class="col-span-2 bg-white">
          <div class="py-4">
            <div class="relative document--heading">
              <div v-html="headingText" />
              <heading-context-menu
                v-if="currentHeading"
                :current-heading="currentHeading"
                class="absolute right-[12px] top-[12px]"
              />
            </div>

            <document-detail-blocks
              ref="documentDetailBlocks"
              :document="document"
              :blocks="blocks"
              @changeHeading="changeHeading"
              @finishedLoading="isLoading = false"
            />
          </div>
        </div>
        <div
          v-else
          class="col-span-2"
          :class="{ 'w-full': isFileOnlyDocument }"
        >
          <iframe
            title="PDF Viewer"
            :src="`/pdfjs/web/viewer.html?file=${pdfUrl}&search=${searchTerm}`"
            class="h-[72vh] w-full"
          />
        </div>

        <block-links class="col-span-1 ml-4 max-w-[600px]" />
      </div>

      <document-print
        v-if="isPrinting"
        ref="printDom"
        class="hidden"
        :blocks-to-print="blocksToPrint"
        :document="document"
      />
    </div>
    <div
      v-if="documentDoesNotExist"
      class="h-full px-10 py-6 my-16 bg-white shadow-md mx-9"
    >
      <div class="px-8 py-6">
        <h2 class="text-4xl">{{ $t('document.doesNotExist') }}</h2>
        <p class="mt-16 text-base">{{ $t('document.reasons') }}</p>
        <ul class="list-disc list-inside">
          <li>{{ $t('document.documentWasDeleted') }}</li>
          <li>{{ $t('document.noAccessRights') }}</li>
        </ul>
        <p class="mt-3 text-base">
          {{ $t('document.solution') }}
          <a :href="`mailto:${$t('document.supportEmail')}`">{{
            $t('document.supportEmail')
          }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getDocumentById } from '@/services/document';
import DocumentDetailBlocks from '@/components/user/DocumentDetailBlocks.vue';
import BlockTableOfContents from '@/components/BlockTableOfContents.vue';
import DocumentVersions from '@/components/DocumentVersions.vue';
import Blocks from '@/helpers/blocks';
import { flattenTheTree } from '@/helpers/blockList';
import BlockLinks from '@/components/user/BlockLinks.vue';
import DocumentActionLinks from '@/components/user/DocumentActionLinks.vue';
import DocumentPrint from '@/components/user/DocumentPrint.vue';
import UserEventBus from '@/helpers/UserEventBus';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { setLanguage } from '@/services/language.service';
import Notification from '@/components/Notification';
import HeadingContextMenu from '@/components/user/HeadingContextMenu.vue';
import DocumentProtectedNotification from '@/components/user/DocumentProtectedNotification.vue';
import DocumentCollapseHeader from '@/components/user/DocumentCollapseHeader.vue';
import DocumentState from '@/components/DocumentState';
import { textWithoutLinks } from '@/helpers/links';

export default {
  components: {
    DocumentDetailBlocks,
    BlockTableOfContents,
    DocumentVersions,
    BlockLinks,
    DocumentActionLinks,
    DocumentPrint,
    LoadingSpinner,
    Notification,
    HeadingContextMenu,
    DocumentProtectedNotification,
    DocumentState,
    DocumentCollapseHeader,
  },
  data() {
    return {
      document: null,
      blockList: null,
      currentHeading: null,
      documentDoesNotExist: false,
      isPrinting: false,
      blocksToPrint: [],
      isLoading: true,
      isHeaderCollapsed: false,
    };
  },
  computed: {
    tableOfContents() {
      return this.blockList.getTableOfContents();
    },
    blocks() {
      // Remove root block
      return this.blockList.getBlocks().slice(1);
    },
    pdfUrl() {
      return `${process.env.VUE_APP_MEDIA_URL}${this.document.pdf}#view=FitH`;
    },
    isFileOnlyDocument() {
      return (
        this.document.importType === 'file_only' ||
        this.document.importType === 'court_decision'
      );
    },
    headingText() {
      if (!this.currentHeading) {
        return '';
      }
      return textWithoutLinks(this.currentHeading.text);
    },
    searchTerm() {
      const query = this.$route.query.q;
      if (query) return query.replaceAll('*', '');
      return '';
    },
    isProtectedPDF() {
      return this.document.typeOfLaw.isProtected;
    },
  },
  async mounted() {
    UserEventBus.$on('print', this.print);
    const id = this.$route.params.id;

    try {
      let language = this.$i18n.locale;
      if (this.$route.query.lang) {
        language = this.$route.query.lang;
        setLanguage(language);
      }
      const document = await getDocumentById(
        id,
        {
          lang: language,
        },
        true,
        this.$route.query.loadNewest,
        true
      );
      this.document = document;

      // If the page was called with loadNewest, we may get an ID that is newer
      // than the one in the URL, so we need to redirect to the newest entry.
      if (id !== this.document.id) {
        await this.$router.push({
          name: this.$route.name,
          params: { ...this.$route.params, id: this.document.id },
        });
      }

      if (!this.isFileOnlyDocument) {
        const blocks = flattenTheTree(this.document.block[0]);
        this.blockList = new Blocks(blocks);
      } else {
        this.isLoading = false;
      }
    } catch {
      this.isLoading = false;
      this.documentDoesNotExist = true;
    }

    if (!this.document) {
      return;
    }

    this.$nextTick(() => {
      if (
        this.document.documentLanguages.length === 1 &&
        this.document.retrievedLanguage !== this.$i18n.locale
      ) {
        this.$refs.notification.$children[0].addItem({
          type: 'notice',
          text: this.$t('document.onlyInOneLanguage'),
        });
      } else if (!this.document.documentLanguages.includes(this.$i18n.locale)) {
        this.$refs.notification.$children[0].addItem({
          type: 'notice',
          text: this.$t('document.openInOtherLanguage'),
        });
      }

      // Wait till the next paint is done. (to be sure that everything is rendered)
      window.requestAnimationFrame(() => {
        if (this.$refs.documentDetailBlocks && this.searchTerm !== '') {
          let searchTerm = this.$route.query.q;
          searchTerm = searchTerm.replaceAll('*', '');
          const searchTerms = searchTerm.split(' ');

          // Build regex string to be highlighted.
          searchTerms.forEach((term) => {
            searchTerm += '|' + term;
          });

          const searchTermRegex = new RegExp(
            '(' + searchTerm + ')(?!([^<]+)?>)',
            'gi'
          );

          // Highlight every word of the search term, that matches the regex, eg. the whole searchterm and every word in it.
          this.$refs.documentDetailBlocks.$el.innerHTML =
            this.$refs.documentDetailBlocks.$el.innerHTML.replace(
              searchTermRegex,
              '<span class="bg-yellow">$1</span>'
            );

          // gets the first block in the array that matches the expression
          let tmpBlock = this.blocks.find((block) => {
            return block.text?.match(searchTermRegex);
          });

          // repetably get parent of block, until block is either a sticky block or has not parent
          while (tmpBlock && !this.isStickyBlock(tmpBlock)) {
            if (!tmpBlock?.parent) {
              tmpBlock = null;
              break;
            }
            tmpBlock = this.blocks.find((block) => block.id == tmpBlock.parent);
          }

          // scroll to first sticky block with marked text
          if (tmpBlock) this.scrollTo(tmpBlock);
        }
      });
    });
  },
  beforeDestroy() {
    UserEventBus.$off('print', this.print);
  },
  methods: {
    setHeaderCollapsed(isCollapsed) {
      this.isHeaderCollapsed = isCollapsed;
    },
    scrollTo(block) {
      this.$refs.documentDetailBlocks.scrollTo(block);
    },
    isStickyBlock(block) {
      return this.$refs.documentDetailBlocks.isStickyBlock(block);
    },
    changeHeading(block) {
      this.currentHeading = block;
    },
    async print() {
      this.isPrinting = true;

      this.blocksToPrint = [];

      // Get all blocks that are "under" the current heading
      const loopChildren = (child) => {
        this.blocksToPrint.push(child);
        if (child.children) {
          child.children.forEach((innerChild) => loopChildren(innerChild));
        }
      };

      loopChildren(this.currentHeading);

      // Wait till the blocks are rendererd
      await this.$nextTick();

      // Get the print html
      const printDom = this.$refs.printDom.$el.innerHTML;

      // Get the tailwind styles
      const styleElements = window.document.getElementsByTagName('style');
      const internalStyles = [...styleElements].map((style) => style.innerHTML);

      const styleString = [...internalStyles].join(' ');

      const entireDom = `<html><head>
        <meta charset="utf-8">
        <style>${styleString}</style>
        <script>
          window.addEventListener('load', () => {window.opener.postMessage('load')})
        <\/script>
        </head><body class="print-body">
        ${printDom}</body></html>`;

      var printW = window.open('');

      window.addEventListener('message', (message) => {
        if (message.data === 'load') {
          printW.focus();
          printW.print();
          printW.close();

          this.isPrinting = false;
        }
      });

      printW.document.write(entireDom);
      printW.document.close();
    },
  },
};
</script>

<style lang="postcss" scoped>
.links-title {
  @apply w-full;
  @apply overflow-hidden font-sansbold text-white whitespace-nowrap overflow-ellipsis;
}

/deep/ .vue-notification-group {
  @apply absolute;
}

.document--heading {
  @apply h-11 z-10;
  @apply pr-16 pl-24 xl:pl-32 pt-4 pb-1;
  @apply overflow-hidden overflow-ellipsis whitespace-nowrap;
  @apply font-sansbold text-secondary-600 shadow-md;
}

.header--transform {
  transition: opacity 0.8s ease, max-height 1s ease, padding 1.5s ease;
}

.hidden-header {
  opacity: 0;
  max-height: 0;
  padding: 0;
  overflow: hidden;
}
</style>
